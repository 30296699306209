import React from 'react'
import { Form, Grid, Tag } from 'tabler-react'
import { CampRegistrationOption } from '../../../graphql/new/graphql'
import { formatMoney } from '../../../utils/numberFormat'
import { roleDisciplineList } from './StudentRegistrationForm'

interface IStudentRegistrationOptionsFormProps {
  registrationOptions: CampRegistrationOption[]
  loading: boolean
  handleOptionsToggle: any
  campRequirements: any
}

const StudentRegistrationOptionsForm = ({
  registrationOptions,
  loading,
  handleOptionsToggle,
  campRequirements
}: IStudentRegistrationOptionsFormProps) => {
  console.log(registrationOptions)
  const filteredOptions = registrationOptions.filter((ro) => {
    if (ro.option_type_id === '5') {
      return (
        campRequirements?.role &&
        roleDisciplineList.includes(campRequirements?.role)
      )
    }
    return true
  })
  if (filteredOptions.length === 0) {
    return <Form.Group label="No Options Available"></Form.Group>
  }
  return (
    <Form.Group label="Options:">
      {!loading &&
        filteredOptions.map((option) => {
          console.log(option)
          return (
            <Grid.Row key={option.id}>
              <Grid.Col>
                <Form.Switch
                  name="status"
                  label={
                    <>
                      <p className="mb-0">
                        <strong>{option.name}</strong>
                      </p>
                      <p>
                        {formatMoney(Number(option.option_value))}{' '}
                        <span className='text-muted'>{option.fee_type_name.toLowerCase()}</span>
                        <Tag className="ml-3">{option.option_type_name.toUpperCase()}</Tag>
                      </p>
                    </>
                  }
                  value={option.id}
                  onChange={(e) => handleOptionsToggle(e.target)}
                />
              </Grid.Col>
            </Grid.Row>
          )
        })}
    </Form.Group>
  )
}

export default StudentRegistrationOptionsForm
