import { useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useMemo } from 'react'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { Button, Card, Grid } from 'tabler-react'
import { DELETE_USER_LOCATION } from '../../../graphql/DELETE_USER_LOCATION'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import { GET_ORG_CREDENTIALS } from '../../../graphql/GET_ORG_CREDENTIALS'
import { GET_STUDENT_GEARS } from '../../../graphql/GET_STUDENT_GEARS'
import { GET_USER_LOCATION } from '../../../graphql/GET_USER_LOCATION'
import { UPDATE_USER_LOCATION } from '../../../graphql/UPDATE_USER_LOCATION'
import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { Types } from '../../../types/graphql'
import { EUSPATitles, lifetimeMemberTemplateId } from '../../constants'
import AADGearForm from '../../Gear/Form/AADGearForm'
import MainGearForm from '../../Gear/Form/MainGearForm'
import RegistrationOrgCredentialForm from '../../OrgCredentials/Forms/RegistrationOrgCredentialForm'
import RegistrationOrgMeritForm from '../../OrgCredentials/Forms/RegistrationOrgMeritForm'
import autoCompleteStyles from './autocompleteStyles'

interface IStudentRegistrationMeritsLocationFormProps {
  studentId: number
  studentRequirements: any
  camp: any
  userDzLocation: any
  userTunnelLocation: any
  studentData: any
}

const StudentRegistrationMeritsLocationForm = ({
  studentId,
  studentRequirements,
  camp,
  userDzLocation,
  userTunnelLocation,
  studentData
}: IStudentRegistrationMeritsLocationFormProps) => {
  // Location related
  const [submitStudentLocation] = useMutation(UPDATE_USER_LOCATION, {
    onCompleted: (result) => {
      if (result.updateUserLocation) {
        toast.success('User location updated')
      }
    }
  })
  const [deleteUserLocation] = useMutation(DELETE_USER_LOCATION, {
    onCompleted: (result) => {
      if (result.deleteUserLocation) {
        toast.success('User location removed')
      }
    }
  })

  const { loadOptions: loadDzOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: 'dropzone' } }
  })

  const { loadOptions: loadTunnelOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: 'tunnel' } }
  })

  // AAD related data
  const [isAADFormOpen, setIsAADFormOpen] = React.useState(false)
  const [isMainFormOpen, setIsMainFormOpen] = React.useState(false)
  const [isOrgFormOpen, setIsOrgFormOpen] = React.useState(false)
  const [isMeritFormOpen, setIsMeritFormOpen] = React.useState(false)
  // GET STUDENT GEARS
  const { data: gearsData, loading: gearsLoading } = useQuery(
    GET_STUDENT_GEARS,
    {
      variables: {
        getUserGearOptions: {
          student_id: Number(studentId)
        }
      }
    }
  )

  // GET MAIN FROM GEAR LIST
  const studentMainCanopy = useMemo(() => {
    if (!Object.keys(studentRequirements).includes('main')) return null

    if (!gearsLoading && gearsData?.getUserGears) {
      const canopy = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 1
      )

      if (canopy.length <= 0) {
        studentRequirements.main = false
        return null
      }

      studentRequirements.main = true
      return canopy[0]
    }

    return null
  }, [gearsData, gearsLoading, studentRequirements])

  // GET AAD FROM GEAR LIST
  const studentAAD = useMemo(() => {
    if (Object.keys(studentRequirements).includes('aad')) {
      if (!gearsLoading && gearsData?.getUserGears) {
        const aad = gearsData.getUserGears.filter(
          (gear) => gear.gear_model.gear_type_id === 4
        )

        if (aad.length === 0) {
          studentRequirements.aad = false
          return null
        }

        studentRequirements.aad = true
        return aad[0]
      }
    }
    return null
  }, [gearsData, gearsLoading, studentRequirements])

  const updateUserLocationHandle = (id, locationId, action, type) => {
    const refetch = [
      {
        query: GET_USER_LOCATION,
        variables: {
          student_id: studentId,
          flag_type_id: 6,
          location_type: type === 'dz' ? 'dropzone' : 'tunnel'
        }
      }
    ]

    if (action === 'add') {
      submitStudentLocation({
        variables: {
          options: {
            id: id ?? undefined,
            student_id: studentId,
            location_id: locationId,
            flag_type_id: 6
          }
        },
        refetchQueries: refetch
      })
    } else {
      deleteUserLocation({
        variables: {
          id
        },
        refetchQueries: refetch
      })
    }
  }

  // ORG RELATED CODES
  const { data: studentManualCredentials, loading: manualCredentialsLoading } =
    useQuery(GET_ORG_CREDENTIALS, {
      variables: { studentId }
    })

  const orgCredentials = useMemo(() => {
    if (!manualCredentialsLoading && studentManualCredentials) {
      return (
        studentManualCredentials.orgCredentials.filter(
          (oc) =>
            oc.membership &&
            ((camp.org_id && oc.org_id === camp.org_id) || !camp.org_id)
        ) ?? []
      )
    }
    return []
  }, [manualCredentialsLoading, studentManualCredentials])

  const orgCredential = orgCredentials[0] ?? null

  const orgMerits = useMemo(() => {
    if (!manualCredentialsLoading && studentManualCredentials) {
      return studentManualCredentials.orgCredentials
        .filter((oc) => (oc.license || oc.nac_id) && oc.org_id === camp.org_id)
        .sort((a, b) => {
          return a.license.localeCompare(b.license)
        })
    }
    return []
  }, [manualCredentialsLoading, studentManualCredentials])

  // MERITS
  const hasAuthMerit = useMemo(() => {
    const exist = studentData.auth_merits.length > 0 || orgMerits.length > 0
    if (exist) {
      studentRequirements.merits = true
    }
    return exist
  }, [studentData, orgMerits])

  // ACTIVE USPA
  const hasLifetimeMembership = useMemo(() => {
    if (studentData) {
      const uspa = studentData.uspa
      return uspa.some((item) => item.template_id === lifetimeMemberTemplateId)
    }
    return false
  }, [studentData])

  const memberLicense = useMemo(() => {
    if (studentData) {
      const uspa = studentData.uspa
      if (hasLifetimeMembership) {
        return uspa.find((item) => item.template_title === 'Lifetime Member')
      }

      return uspa.find((item) => item.template_title === EUSPATitles.Member)
    }
    return null
  }, [hasLifetimeMembership, studentData])

  const hasMembership = useMemo(() => {
    const exist =
      orgCredentials.length > 0 ||
      (memberLicense &&
        (hasLifetimeMembership ||
          moment(memberLicense.merits_uspa_member_expiration) >
            moment(camp.end)))

    if (exist && Object.keys(studentRequirements).includes('member')) {
      studentRequirements.member = true
    }
    return exist
  }, [camp, hasLifetimeMembership, memberLicense, orgCredentials])

  const renderForm = ({ values, setFieldValue }) => (
    <>
      {Object.keys(studentRequirements).includes('member') && (
        <>
          <Grid.Row>
            <Grid.Col>
              {hasMembership ? (
                <>
                  <Button
                    icon="check-circle"
                    color="white"
                    className="text-success mr-2 mb-1"
                    onClick={() => {
                      setIsOrgFormOpen(true)
                    }}
                    disabled={orgCredential && !!orgCredential.verified_by}
                  >
                    {orgCredentials.length > 0 && orgCredentials[0].name}
                    <span className="text-muted">
                      {memberLicense
                        ? `${
                            memberLicense.merits_uspa_member_id
                          } - Expires: ${moment(
                            memberLicense.merits_uspa_member_expiration
                          ).format('MM/DD/YYYY')}`
                        : orgCredentials &&
                          `${orgCredentials[0].membership} - Expires: ${moment(
                            orgCredentials[0].expires
                          ).format('MMM DD, YYYY')}`}
                    </span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    icon="plus-circle"
                    color="white"
                    size="sm"
                    className="text-muted mr-2"
                    onClick={() => setIsOrgFormOpen(true)}
                  >
                    ORG Membership
                  </Button>
                  <span className="field-error text-danger">
                    {!studentRequirements.member && 'This field is required'}
                  </span>
                </>
              )}
              {isOrgFormOpen && (
                <Card className="bg-light mt-3 p-3">
                  <RegistrationOrgCredentialForm
                    toggle={() => setIsOrgFormOpen(false)}
                    studentId={studentId}
                    team_id={Number(camp.team_id)}
                    orgCredential={orgCredential}
                  />
                </Card>
              )}
            </Grid.Col>
          </Grid.Row>
{/*
          <Grid.Row>
            <Grid.Col>
              {hasAuthMerit ? (
                <>
                  <Button
                    icon="check-circle"
                    color="white"
                    className="text-success mr-2 mb-3"
                    disabled
                  >
                    <span className="text-muted">
                      {studentData.auth_merits.length > 0
                        ? `${studentData.auth_merits[0].member_id}`
                        : `${orgMerits[0].license} - ${orgMerits[0].license_number}`}
                    </span>
                  </Button>
                  <span className="field-error text-danger">
                    {!studentRequirements.merits && 'This field is required'}
                  </span>
                </>
              ) : (
                <Button
                  icon="plus-circle"
                  color="white"
                  size="sm"
                  className="text-muted"
                  onClick={() => setIsMeritFormOpen(true)}
                >
                  LICENSE
                </Button>
              )}
              {isMeritFormOpen && (
                <Card className="bg-light p-3">
                  <RegistrationOrgMeritForm
                    toggle={() => setIsMeritFormOpen(false)}
                    studentId={studentId}
                    team_id={Number(camp.team_id)}
                    orgCredentials={orgCredentials}
                  />
                </Card>
              )}
            </Grid.Col>
          </Grid.Row>
*/}
        </>
      )}
      {Object.keys(studentRequirements).includes('merits') && ''}
      {Object.keys(studentRequirements).includes('aad') && (
        <Grid.Row>
          <Grid.Col className="mb-3">
            {!studentAAD && !isAADFormOpen && (
              <Button
                icon="plus-circle"
                color="white"
                size="sm"
                className="text-muted"
                onClick={() => setIsAADFormOpen(true)}
              >
                AAD
              </Button>
            )}
            {studentAAD && (
              <>
                <Button
                  icon="check-circle"
                  color="white"
                  className="text-success mb-3"
                  disabled
                >
                  AAD:{' '}
                  <span className="text-muted">
                    {studentAAD.gear_model.name}
                  </span>
                </Button>
              </>
            )}
            {isAADFormOpen && (
              <Card className="bg-light p-3">
                <AADGearForm
                  toggle={() => setIsAADFormOpen(false)}
                  student_id={studentId}
                  team_id={Number(camp.team_id)}
                />
              </Card>
            )}
            <span className="field-error text-danger mr-2">
              {!studentRequirements.aad && 'An AAD is required'}
            </span>
          </Grid.Col>
        </Grid.Row>
      )}

      {Object.keys(studentRequirements).includes('main') && (
        <Grid.Row>
          <Grid.Col className="mb-3">
            {!studentMainCanopy && !isMainFormOpen && (
              <Button
                icon="plus-circle"
                color="white"
                size="sm"
                className="text-muted"
                onClick={() => setIsMainFormOpen(true)}
              >
                Main Canopy
              </Button>
            )}
            {studentMainCanopy && (
              <>
                <Button
                  icon="check-circle"
                  color="white"
                  className="text-success mb-3"
                  disabled
                >
                  Main Canopy:{' '}
                  <span className="text-muted">
                    {studentMainCanopy.gear_model.name}
                  </span>
                </Button>
              </>
            )}
            {isMainFormOpen && (
              <Card className="bg-light p-3">
                <MainGearForm
                  toggle={() => setIsMainFormOpen(false)}
                  student_id={studentId}
                  team_id={Number(camp.team_id)}
                />
              </Card>
            )}
            <span className="field-error text-danger mr-2">
              {!studentRequirements.main &&
                'Main Canopy is required for Wing Load'}
            </span>
          </Grid.Col>
        </Grid.Row>
      )}

      {Object.keys(studentRequirements).includes('home_dz') && (
        <Grid.Row>
          <Grid.Col md={6} sm={12}>
            <h5>Home DZ</h5>
            <AsyncSelect
              autoFocus={true}
              backspaceRemovesValue={true}
              className="input-group"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null
              }}
              escapeClearsValue={true}
              getOptionLabel={(option: Types.Location) => option.name}
              getOptionValue={(option: Types.Location) => option.id}
              isClearable={true}
              loadOptions={loadDzOptions}
              name="dz_location_id"
              onChange={(e, change) => {
                if (
                  change.action === 'clear' &&
                  userDzLocation?.user_location_id
                ) {
                  updateUserLocationHandle(
                    userDzLocation?.user_location_id,
                    e?.id,
                    'remove',
                    'dz'
                  )
                } else {
                  if (e) {
                    setFieldValue('dz_location_id', Number(e.id))
                    setFieldValue('dz_location_name', e.name)
                    updateUserLocationHandle(
                      userDzLocation?.user_location_id,
                      Number(e.id),
                      'add',
                      'dz'
                    )
                  } else setFieldValue('dz_location_id', null)
                }
              }}
              value={
                values.dz_location_id
                  ? {
                      id: values.dz_location_id,
                      name: values.dz_location_name
                    }
                  : null
              }
              placeholder="Search dropzones"
              styles={autoCompleteStyles}
            />
            <span className="field-error text-danger">
              {!studentRequirements.home_dz && 'This field is required'}
            </span>
          </Grid.Col>
        </Grid.Row>
      )}

      {Object.keys(studentRequirements).includes('home_tunnel') && (
        <Grid.Row className="mt-5">
          <Grid.Col md={6} sm={12}>
            <h5>Home Tunnel</h5>
            <AsyncSelect
              backspaceRemovesValue={true}
              className="input-group"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null
              }}
              escapeClearsValue={true}
              getOptionLabel={(option: Types.Location) => option.name}
              getOptionValue={(option: Types.Location) => option.id}
              isClearable={true}
              loadOptions={loadTunnelOptions}
              name="tunnel_location_id"
              onChange={(e, change) => {
                if (
                  change.action === 'clear' &&
                  userTunnelLocation?.user_location_id
                ) {
                  updateUserLocationHandle(
                    userTunnelLocation?.user_location_id,
                    e?.id,
                    'remove',
                    'tnl'
                  )
                } else {
                  if (e) {
                    setFieldValue('tunnel_location_id', Number(e.id))
                    setFieldValue('tunnel_location_name', e.name)
                    updateUserLocationHandle(
                      userTunnelLocation?.user_location_id,
                      Number(e.id),
                      'add',
                      'tnl'
                    )
                  } else setFieldValue('tunnel_location_id', null)
                }
              }}
              value={
                values.tunnel_location_id
                  ? {
                      id: values.tunnel_location_id,
                      name: values.tunnel_location_name
                    }
                  : null
              }
              placeholder="Search tunnels"
              styles={autoCompleteStyles}
            />
            <span className="field-error text-danger">
              {!studentRequirements.home_tunnel && 'This field is required'}
            </span>
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  )
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        home_dz: studentRequirements?.home_dz,
        home_tunnel: studentRequirements?.home_tunnel,
        aad: studentRequirements?.aad,
        merits: studentRequirements?.merits,
        uspa_active: studentRequirements?.uspa_active,
        dz_location_id: userDzLocation?.id,
        dz_location_name: userDzLocation?.name,
        tunnel_location_id: userTunnelLocation?.id,
        tunnel_location_name: userTunnelLocation?.name
      }}
      onSubmit={async (values) => {}}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}
export default StudentRegistrationMeritsLocationForm
