import StudentSearchForm from "components/Students/Forms/StudentSearchForm";
import { useRootStore } from "hooks";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Nav } from "tabler-react";

const EventsNav = () => {
  const { currentUser, currentCoachTeam } = useRootStore();

  return (
    <>
      <Nav.Item icon="home" to="/" value="/" />
      <FeatureAccessGate feature="feature_products">
        <Nav.Item icon="shopping-bag" to="/products" value="Products" />
      </FeatureAccessGate>
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link
                to="/user/calendar"
                className="dropdown-item d-none d-md-block"
              >
                Calendar
              </Link>
              <Link to="/events" className="dropdown-item">
                Events
              </Link>
            </>
          }
        />
        {(currentCoachTeam?.feature_invoices ||
          currentCoachTeam?.feature_payments ||
          currentCoachTeam?.feature_app_reports) && (
          <Dropdown
            toggle={false}
            icon="pie-chart"
            position="bottom-end"
            flex
            className="mr-4 dropdown-header-icon-lg"
            items={
              <>
                <FeatureAccessGate feature="feature_invoices">
                  <Link to="/invoices" className="dropdown-item">
                    Invoices
                  </Link>
                </FeatureAccessGate>
                <Link to="/payments" className="dropdown-item">
                  Payments
                </Link>
                <FeatureAccessGate feature="feature_app_reports">
                  <Link to="/dzm/reports" className="dropdown-item">
                    Reports
                  </Link>
                </FeatureAccessGate>
                <FeatureAccessGate feature="feature_accounting">
                <Link to="/transactions" className="dropdown-item">
                  Transactions
                </Link>
              </FeatureAccessGate>
              </>
            }
          />
        )}
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 ml-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/events/participants" className="dropdown-item">
                Participants
              </Link>
              <Link to="/staff" className="dropdown-item">
                TEAM Accounts
              </Link>
            </>
          }
        />
        <StudentSearchForm />
      </li>
    </>
  );
};

export default EventsNav;
