import BulkEmailModal from 'components/Event/Modals/BulkEmailModal'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
  Avatar,
  Button,
  Card,
  Form,
  Grid,
  Icon,
  Table,
  Text
} from 'tabler-react'
import { Types } from 'types/graphql'
import useReactRouter from 'use-react-router'
import { OrgMembershipModal } from '../../../components/Event/EventRegistrationListItem'

type EventControllerProps = {
  campData: Types.Camp
  registeredStudents: Types.RegisteredStudents[]
}
const EventController = ({
  campData,
  registeredStudents
}: EventControllerProps) => {
  const { history } = useReactRouter()
  const [selectedParticipant, setSelectedParticipant] = useState<
    Types.RegisteredStudents[]
  >([])
  const [isBulkEmailModalOpen, setIsBulkEmailModalOpen] =
    useState<boolean>(false)

  const getAge = (dateString) => {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const participants = useMemo(() => {
    if (!registeredStudents) return []

    const validStatuses = ['Confirmed', 'Accepted'];
    return registeredStudents.filter(
      (student) => validStatuses.includes(student.status)
    );
  }, [registeredStudents])

  const participantsCSV = useMemo(() => {
    if (!registeredStudents) return []

    return registeredStudents
      .filter((student) => student.status === 'Confirmed')
      .map((student) => {
        let studentOrg = null
        let studentLicense = null
        if (student.orgCredentials.length > 0) {
          studentOrg = student.orgCredentials[0]
          studentLicense = student.orgCredentials.find(
            (org) =>
              org.org_id === studentOrg.org_id && (org.license || org.nac_slug)
          )
        }
        return {
          userId: student.student_id,
          name: student.first_name + ' ' + student.last_name,
          registrationId: student.user_registrations_id,
          code: student.registration_code,
          address: `${student?.city ?? ''} ${student?.province ?? ''}`,
          org: studentOrg?.org_slug?.toUpperCase(),
          membership: studentOrg?.membership,
          expires: studentOrg?.expires
            ? moment(studentOrg.expires).format('YYYY-MM-DD')
            : '',
          license: studentLicense?.nac_slug
            ? `${
                (studentLicense?.nac_slug?.toUpperCase() ?? '') +
                ' ' +
                (studentLicense?.license_number ?? '')
              }`
            : `${
                (studentLicense?.license ?? '') +
                ' ' +
                (studentLicense?.license_number ?? '')
              }`,
          dob: student?.dob ? moment(student.dob).format('YYYY-MM-DD') : '',
          gender: student.gender,
          country: studentLicense?.nac_country
        }
      })
  }, [registeredStudents])

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleToggleSelectAll = (selectedAll) => {
    if (selectedAll) {
      setSelectedParticipant(
        registeredStudents.filter((student) => student.status === 'Confirmed')
      )
    } else {
      setSelectedParticipant([])
    }
  }

  const handleToggleSelected = (id: number) => {
    const pool = registeredStudents.filter(
      (student) => student.status === 'Confirmed'
    )
    if (
      selectedParticipant?.find((student) => {
        return student.user_registrations_id === id
      })
    ) {
      setSelectedParticipant(
        (prev) => prev?.filter((p) => p.user_registrations_id !== id) ?? []
      )
    } else {
      setSelectedParticipant((prev) => [
        ...(prev ?? []),
        pool.find((student) => student.user_registrations_id === id)
      ])
    }
  }

  return (
    <>
      <Card.Body>
        <Grid.Row className="p-3">
          <Grid.Col width={1} className="pt-2">
            <Form.Label>
              <input
                id={`selectAll`}
                name={'select_all'}
                type="checkbox"
                checked={selectAll}
                onClick={() => {
                  setSelectAll(!selectAll)
                  handleToggleSelectAll(!selectAll)
                }}
                onChange={() => {}}
                className="float-left pb-2"
              />
            </Form.Label>
          </Grid.Col>
          <Grid.Col width={11} className="text-right pb-2">
            <Button
              outline={selectedParticipant.length !== 0}
              icon="mail"
              color={selectedParticipant.length !== 0 ? 'secondary' : 'white'}
              size={'sm'}
              className="mr-2"
              onClick={() => {
                setIsBulkEmailModalOpen(true)
              }}
              disabled={selectedParticipant.length === 0}
            >
              EMAIL
            </Button>
            <Button outline color="secondary" size="sm">
              <CSVLink
                data={participantsCSV}
                filename="controller_report.csv"
                className="ml-0 text-secondary"
              >
                <Icon name="download" className="mr-1" />
                CSV
              </CSVLink>
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Table responsive className="table-vcenter text-nowrap">
              <Table.Body>
                {participants.map((student) => {
                  let studentOrg = null
                  let studentLicense = null
                  if (student.orgCredentials.length > 0) {
                    studentOrg = student.orgCredentials[0]
                    studentLicense = student.orgCredentials.find(
                      (org) =>
                        org.org_id === studentOrg.org_id &&
                        (org.license || org.nac_slug)
                    )
                  }
                  let campGroupName = ''
                  if (student.campGroups && student.campGroups.length > 0)
                    campGroupName = student.campGroups[0].name

                  let statusIcon = 'bg-danger'

                  if (
                    moment(student.end).diff(studentOrg?.expires, 'days') > 0
                  ) {
                    statusIcon = 'bg-warning'
                  }

                  if (
                    moment(student.end).diff(studentOrg?.expires, 'days') < 0
                  ) {
                    statusIcon = 'bg-success'
                  }

                  if (moment().diff(studentOrg?.expires, 'days') > 0) {
                    statusIcon = 'bg-danger'
                  }

                  return (
                    <Table.Row key={student.id}>
                      <Table.Col>
                        <input
                          id={`participant-${student.student_id}`}
                          name={'selected'}
                          type="checkbox"
                          checked={
                            !!selectedParticipant.find(
                              (p) =>
                                p.user_registrations_id ===
                                student.user_registrations_id
                            )
                          }
                          onClick={() =>
                            handleToggleSelected(student.user_registrations_id)
                          }
                          onChange={() => {}}
                        />
                      </Table.Col>

                      <Table.Col>
                        <Avatar
                          size="md"
                          className="cursor-pointer"
                          status={student.status_color ?? 'danger'}
                          imageURL={
                            student?.profile_avatar
                              ? `/api/s3/uploads/${student.profile_avatar}`
                              : '//www.gravatar.com/avatar?d=mp'
                          }
                          onClick={() =>
                            history.push(
                              `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                            )
                          }
                        />
                      </Table.Col>
                      <Table.Col>
                        <strong>
                          <Text
                            className="cursor-pointer d-block"
                            onClick={() =>
                              history.push(
                                `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                              )
                            }
                          >
                            {student.first_name + ' ' + student.last_name}
                          </Text>
                          <Text.Small className="d-block text-muted">
                            {student.city ?? ''}
                            {student.province && ', ' + student.province}
                            {student.country !== 'United States'
                              ? student.country
                              : ''}
                          </Text.Small>
                        </strong>
                      </Table.Col>
                      <Table.Col>
                        {studentOrg?.membership ? (
                          <>
                            <Text>
                              {(studentOrg?.org_slug.toUpperCase() ?? '') +
                                ' ' +
                                (studentOrg?.membership ?? '')}
                            </Text>
                            <Text.Small className="d-block">
                              {studentOrg?.expires ? (
                                <>
                                  <span
                                    className={`status-icon ${statusIcon}`}
                                  />
                                  {studentOrg?.expires}
                                </>
                              ) : (
                                ''
                              )}
                            </Text.Small>
                          </>
                        ) : (
                          <>
                            <OrgMembershipModal
                              studentId={student.student_id}
                              teamId={campData.team_id}
                            />
                          </>
                        )}
                      </Table.Col>
                      <Table.Col>
                        {studentLicense?.nac_slug
                          ? `${
                              (studentLicense?.nac_slug?.toUpperCase() ?? '') +
                              ' ' +
                              (studentLicense?.license_number ?? '')
                            }`
                          : `${
                              (studentLicense?.license ?? '') +
                              ' ' +
                              (studentLicense?.license_number ?? '')
                            }`}
                      </Table.Col>
                      <Table.Col>
                        {student.dob && (
                          <>
                            <Text>
                              <strong>{getAge(student.dob) + ' '}</strong>
                            </Text>
                            <Text.Small className="text-muted">
                              {'DOB: ' +
                                moment(student.dob).format('YYYY-MM-DD') +
                                ' '}
                            </Text.Small>
                          </>
                        )}
                      </Table.Col>
                      {/* Only display Gender and Country if it is a requirement for the event, otherwise do not display */}
                      <Table.Col className="text-capitalize">
                        {student.gender}
                      </Table.Col>
                      <Table.Col>{studentLicense?.nac_country}</Table.Col>
                      <Table.Col>
                        <Icon name="more-vertical" />
                      </Table.Col>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <BulkEmailModal
              isOpen={isBulkEmailModalOpen}
              toggle={() => setIsBulkEmailModalOpen(false)}
              students={selectedParticipant}
              teamId={Number(campData.team_id)}
              campId={Number(campData.id)}
            />
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  )
}

export default EventController
